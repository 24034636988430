<template>
    <y-modal
        ref="Modal"
        class="timeline-node-modal"
        :size="size"
    >
        <y-loading
            :active="$wait.is('loading-node-details')"
            height="100px"
        >
            <div v-if="node">
                <div class="node-modal-header">
                    <header
                        class="node-header"
                        :class="[nodeColor]"
                    >
                        <div class="right">
                            <span
                                v-if="nodeIcon"
                                class="icon"
                                :class="[nodeIcon]"
                            />
                            <div class="title">
                                {{ node.title }}
                            </div>
                        </div>
                        <div class="left">
                            <div class="details">
                                <div class="details-meta">
                                    <span v-if="at">{{ at | date('YYYY/M/D - HH:mm', $i18n.locale) | digits }}</span>
                                    <span v-if="by">{{ $t('general.timeline.by', [by]) }}</span>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>

                <article class="node-content">
                    <div v-if="content">
                        <slot :content="content" />
                    </div>
                </article>
            </div>
        </y-loading>
    </y-modal>
</template>

<script>

    import { YModal } from '@deps';

    export default {

        name: 'TimelineNodeModal',

        components: {
            YModal,
        },

        props: {
            color: String,
            icon : String,
            size : {
                type   : String,
                default: 'md',
            },
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                content: null,
                node   : null,
            };
        },

        computed: {

            /**
             * Return created at
             */
            at() {
                return this.get(this.node, 'created_at');
            },

            /**
             * Return creator name
             */
            by() {
                return this.get(this.node, 'created_by.full_name');
            },

            /**
             * Return node icon
             */
            nodeIcon() {
                return this.icon || this.get(this.node, 'icon');
            },

            /**
             * Return node color
             */
            nodeColor() {
                return this.color || this.get(this.node, 'color');
            },

        },

        methods: {

            /**
             * Get Node details
             *
             * @param id
             */
            fetch(id) {
                this.$wait.start('loading-node-details');
                return this.$services.Timeline.node({ id }).then((response) => {
                    this.node = response.data.metadata.node;
                    this.content = response.data.results;
                }).catch((error) => {
                    this.handleError(error);
                    this.close();
                }).finally(() => this.$wait.end('loading-node-details'));
            },

            /**
             * Open modal
             *
             * @param id
             */
            open(id) {
                this.fetch(id);
                this.$refs.Modal.open();
            },

            /**
             * Close modal
             */
            close() {
                this.title = ' ';
                this.content = null;
                this.$refs.Modal.close();
            },

        },

    };

</script>
